const isEn = (path: string) => /^\/en\//.test(path)

export default defineNuxtRouteMiddleware((to, from) => {
  // 以前のフォローページにアクセスした場合は、新しいフォローページにリダイレクトする
  if (/^\/(en\/)?(mypage\/)?(relationship\/following)\/?$/.test(to.path)) {
    const redirectPath = isEn(to.path) ? '/en/mypage/follow' : '/mypage/follow'
    navigateTo(redirectPath, { redirectCode: 301 })
  }

  // 以前のフォロワーページにアクセスした場合は、新しいフォロワーページにリダイレクトする
  if (/^\/(en\/)?(mypage\/)?(relationship\/follower)\/?$/.test(to.path)) {
    const redirectPath = isEn(to.path)
      ? '/en/mypage/follower'
      : '/mypage/follower'
    navigateTo(redirectPath, { redirectCode: 301 })
  }
})
