import Vue3Toastify, {
  toast,
  ToastContainerOptions,
  CSSTransitionProps,
} from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const options: ToastContainerOptions = {
    position: toast.POSITION.TOP_LEFT,
    hideProgressBar: true,
    autoClose: 4000,
    limit: 5,
    containerClassName: 'toast-container',
    toastClassName: 'toast',
    icon: false,
  }
  nuxtApp.vueApp.use(Vue3Toastify, options)

  return {
    provide: { toast },
  }
})
