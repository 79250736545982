<i18n lang="yaml">
ja:
  message:
    1: アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
    2: お手数ですがTOPに戻るまたはお問い合わせください。
  back: TOPに戻る
  contact: お問い合わせ
en:
  message:
    1: The webpage was not available this time. The page may have been deleted, changed, or got temporarily unavailable.
    2: Please return to homepage or contact us.
  back: Return to homepage
  contact: Contact
</i18n>
<template>
  <div class="app">
    <NuxtLayout class="-error-page">
      <div class="error">
        <div class="container">
          <template v-if="error.statusCode === '404'">
            <h1 class="title">404</h1>
          </template>
          <div class="text">
            <p>{{ i18n.t('message.1') }}<br />{{ i18n.t('message.2') }}</p>
          </div>
          <div class="buttons">
            <HaLink to="/">
              <HoMyVketButton
                category="primary"
                @click="clearError({ redirect: '/' })"
                >{{ i18n.t('back') }}</HoMyVketButton
              >
            </HaLink>
            <HaLink :to="LINK.CONTACT">
              <HoMyVketButton outline>{{ i18n.t('contact') }}</HoMyVketButton>
            </HaLink>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  error: { statusCode: string }
}>()

const i18n = useI18n()
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.error {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 32px 16px;

  > .container {
    background: v.$gray;
    padding: 100px;
    @include m.sp() {
      padding: 32px;
    }

    > .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      @include m.sp() {
        font-size: 13px;
      }

      + .text {
        margin-top: 32px;
      }
    }

    > .text {
      text-align: center;
      @include m.sp() {
        font-size: 14px;
        margin-top: 36px;
        text-align: left;
      }
    }

    > .buttons {
      display: grid;
      gap: 24px 32px;
      grid-template-columns: repeat(2, auto);
      justify-content: center;
      margin-top: 32px;
      @include m.sp() {
        grid-template-columns: repeat(1, auto);

        .-primary {
          min-width: 192px;
          padding: 12px 28px;
        }
      }
    }
  }
}
</style>
