import type { InjectionKey } from 'vue'
import { setLocale } from '@vee-validate/i18n'
import { VueI18n } from 'vue-i18n'
import { getSingleCookieValue } from '@/utils/_vaf/storage-control'

export const COOKIE_KEY = 'VUEI18N_MANUAL_LOCALE'
export const JA = 'ja'
export const EN = 'en'
export type Lang = typeof JA | typeof EN

export const useLocale = () => {
  // const i18n = useI18n()
  const nuxtApp = useNuxtApp()

  // useI18nを使用すると /mypage/assets/[id] などで以下のエラーが発生するため、nuxtApp.$i18nを使用する
  // SyntaxError: Must be called at the top of a `setup` function
  const i18n = nuxtApp.$i18n as VueI18n

  /**
   * デフォルトの言語を取得。
   */
  const getDefaultLanguage = () => {
    // 初期日本語
    const reqLocale =
      nuxtApp.ssrContext?.req.headers['accept-language']?.split(',')[0]
    const locale = ref(
      process.server && reqLocale
        ? reqLocale // サーバーサイドでの判定
        : process.client && navigator.language
        ? navigator.language // クライアントでの判定
        : JA
    )

    const isBrowserLanguageJa = locale.value.startsWith(JA)
    const isBrowserLanguageEn = locale.value.startsWith(EN)
    const defaultLanguageFromCookie = getSingleCookieValue(COOKIE_KEY)
    return defaultLanguageFromCookie === JA
      ? JA
      : defaultLanguageFromCookie === EN
      ? EN
      : isBrowserLanguageJa
      ? JA
      : isBrowserLanguageEn
      ? EN
      : JA
  }

  const changeLocale = async (target: 'ja' | 'en') => {
    setLocale(target)
    /**
     * VAFの i18n.locale.value = target だと画面全体の言語変更が行われないため、setLocaleを使う
     * @see https://hikky.atlassian.net/browse/PMVK-3026?focusedCommentId=59961
     */
    await i18n.setLocale(target)
    changeDateLocale(target)
  }

  const localePath = (to: string) => {
    const localePath = useLocalePath()
    /**
     * VAFの i18n.locale.value === JA ? to : `${i18n.locale.value}${to}`
     * だと英語ページへのリンクがおかしくなるため、useLocalePathを使う
     * @see https://hikky.atlassian.net/browse/PMVK-3026?focusedCommentId=59942
     */
    return localePath(to)
  }

  return {
    getDefaultLanguage,
    changeLocale,
    localePath,
  }
}

export type LocaleComposable = ReturnType<typeof useLocale>

export const localeInjectionKey: InjectionKey<LocaleComposable> =
  Symbol('locale')
