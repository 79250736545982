<template>
  <HaBaseButton
    class="ho-my-vket-button"
    :class="[
      `-${category}`,
      { '-disabled': disabled },
      { '-outline': outline },
      { '-light': light },
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <template v-if="category === BUTTON_TYPE.ICON">
      <span class="icon-wrapper">
        <HaBaseImage :src="src" class="icon" />
      </span>
    </template>
    <slot />
  </HaBaseButton>
</template>

<script lang="ts">
import { ValueOf } from '@/utils/types/types'

const BUTTON_TYPE = {
  PRIMARY: 'primary', // Button01, 04, 05
  SECONDARY: 'secondary', // Button02, 06, 07, 08
  INFO: 'info', // Button03
  GRAY: 'gray', // Button09
  DANGER: 'danger',
  ICON: 'icon',
} as const
</script>

<script setup lang="ts">
type Props = {
  disabled?: boolean
  category?: ValueOf<typeof BUTTON_TYPE>
  outline?: boolean
  light?: boolean
  src?: string
}

type Emits = {
  (emit: 'click'): void
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  category: BUTTON_TYPE.PRIMARY,
  outline: false,
  light: false,
  src: '',
})

const emits = defineEmits<Emits>()

const onClick = () => {
  if (props.disabled) return false
  return emits('click')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use 'sass:math';
@use 'sass:color';

.ho-my-vket-button {
  border-radius: 50px;
  color: v.$white;
  cursor: pointer;
  // TODO: HaBaseButtonのVAF移植により消されたので設置。
  display: block;
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  margin: 0 auto;
  padding: v.space(2) v.space(7);
  text-align: center;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  user-select: none;
  width: fit-content;

  @include m.sp {
    height: 40px;
  }

  &.-disabled {
    cursor: default;
  }

  &.-primary:not(.-outline) {
    background-color: v.$primary-color;
    line-height: 1;
    transition: background-color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$yellow;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$button-disabled-text-color;
    }
  }

  &.-primary.-outline {
    background-color: v.$white;
    border: 1px solid v.$primary-color;
    color: v.$primary-color;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$yellow-1;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      border: 0;
      color: v.$button-disabled-text-color;
    }
  }

  &.-primary:not(.-outline).-light {
    background-color: v.$yellow-1;
    color: v.$primary-color;

    &:hover,
    &:focus,
    &:active {
      outline: 1px solid v.$primary-color;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$button-disabled-text-color;
    }
  }

  &.-secondary:not(.-outline) {
    background-color: v.$green-2;
    line-height: 1;
    transition: background-color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$green-3;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$button-disabled-text-color;
    }
  }

  &.-secondary.-outline {
    background-color: v.$white;
    border: 1px solid v.$green-2;
    color: v.$green-2;
    line-height: 1;
    transition: background-color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$green-4;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      border: 0;
      color: v.$button-disabled-text-color;
    }
  }

  &.-secondary:not(.-outline).-light {
    background-color: v.$green-1;
    color: v.$green-2;
    line-height: 1;
    transition: background-color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      outline: 1px solid v.$green-2;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$button-disabled-text-color;
    }
  }

  &.-info {
    background-color: v.$pink-1;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$pink-2;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$button-disabled-text-color;
    }
  }

  &.-gray:not(.-outline) {
    background-color: v.$gray-5;
    color: v.$gray-2;

    &:hover,
    &:focus,
    &:active {
      background-color: v.$primary-color;
    }
  }

  &.-gray.-outline {
    background-color: v.$white;
    border: 1px solid v.$gray-5;
    color: v.$gray-5;
    transition: all 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid v.$primary-color;
      color: v.$primary-color;
    }
  }

  // TODO: style guide確認
  &.-danger {
    background-color: v.$red;

    &:hover,
    &:focus,
    &:active {
      background-color: color.adjust(v.$red, $lightness: 20%);
    }
  }

  &.-icon {
    $icon-size: 40px;
    align-items: center;
    background-color: v.$orange;
    display: inline-flex;
    padding: v.space(0.2);
    padding-right: v.space(4);

    .icon-wrapper {
      background: v.$white;
      border-radius: 50%;
      display: inline-block;
      height: $icon-size;
      margin: 0 v.space(2);
      overflow: hidden;
      width: $icon-size;

      @include m.sp {
        margin-left: 0;
      }

      .icon {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: color.adjust(v.$orange, $hue: 10deg);

      .icon {
        filter: brightness(1.2);
      }
    }
  }
}
</style>
