<!-- コンポーネント名をNuxtLinkにすると循環参照が起こる。通常リンクもこちらに統一する -->
<template>
  <template v-if="redirect">
    <a class="ha-nuxt-link" :href="href" :target="blank ? '_blank' : undefined">
      <slot />
    </a>
  </template>
  <template v-else>
    <NuxtLink
      class="ha-nuxt-link"
      :to="href"
      :target="blank ? '_blank' : undefined"
    >
      <slot />
    </NuxtLink>
  </template>
</template>

<script setup lang="ts">
// composables
import { useLocale } from '@/composables/useLocale'
const locale = useLocale()

const props = withDefaults(
  defineProps<{
    to?: string
    blank?: boolean
    redirect?: boolean
    noLocale?: boolean
  }>(),
  {
    to: '/',
    blank: false,
    noLocale: false,
    redirect: false,
  }
)

const isNoLocale = computed(
  () => props.noLocale || /^(http|https):\/\//.test(props.to)
)

const href = computed(() => {
  if (isNoLocale.value) {
    return props.to
  }

  // queryが消えるので付与して返却
  const [path, ...queries] = props.to.split('?')

  if (!path) return '/'
  if (queries.length === 0) return locale.localePath(path)

  return `${locale.localePath(path)}?${queries.join('?')}`
})
</script>
