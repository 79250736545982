export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    if (history.state.scroll) {
      // ブラウザバックした場合、以前のスクロール位置を保持する
      setTimeout(() => window.scrollTo(history.state.scroll), 0)
    } else {
      setTimeout(() => window.scrollTo(0, 0), 0)
    }
  })
})
