// TODO: HmInputTextのVAF移植に際し、getMaxが無いエラーが出るため、getMaxと依存するimportだけ先に上書きを行った。他箇所をVAFとすり合わせて組み込む必要あり。
import {
  z,
  ZodType,
  ZodTypeDef,
  ZodStringDef,
  ZodUnionDef,
  ZodFirstPartyTypeKind,
} from 'zod'

export function isValueOf<T>(x: ZodType<T, ZodTypeDef>, y: unknown): y is T {
  const a = x.safeParse(y)
  if (!a.success) {
    console.error(a)
    return false
  }
  return true
}

export function ensureValueOf<T>(
  x: ZodType<T, ZodTypeDef>,
  y: unknown
): asserts y is T {
  if (!isValueOf(x, y)) {
    throw new Error(`It is not a value of the specified type. value: ${y}`)
  }
}

export function requireValueOf<T>(x: ZodType<T, ZodTypeDef>, y: unknown): T {
  ensureValueOf(x, y)
  return y
}

/**
 * 雑多に整数値を表す。
 *
 * NOTE:
 * RoomAPI [[@/repositories/roomRepository]] は全プロジェクト共通で用いられているので、突然仕様が変わったりするらしい。
 * つまり2022-03-28現在はstringで整数値が返ってくるが、将来的に突然numberで返ってくるかもしれないらしい。
 * それを対処するために、これを使用する。
 */
export const integral = z.number().or(z.string())

/** zodバリデーション */
export const existResponse = <T>(
  zodObject: ZodType<T, ZodTypeDef, T>,
  response: unknown
): response is T => {
  if (!response) {
    throw new Error('response is empty.')
  }
  if (!isValueOf<T>(zodObject, response)) {
    return false
  }
  return true
}

/**
 * maxバリデーションの数値を取得
 *
 * ```typescript
 * getMax(z.string().max(30)._def) // 30
 *
 * // .max()の指定がないスキーマ
 * getMax(z.string()._def) // undefined
 * ```
 */
export const getMax = (def: ZodTypeDef | undefined): number | undefined => {
  if (!def) return undefined

  const zodTypeDefSchema = z.object({
    errorMap: z.unknown().optional(),
    description: z.string().optional(),
    typeName: z.string(),
  })
  const zodStringDefSchema = z.custom<ZodStringDef>((x: unknown) => {
    const result = zodTypeDefSchema.safeParse(x)
    if (!result.success) return false
    return result.data.typeName === ZodFirstPartyTypeKind.ZodString
  })
  const zodUnionDefSchema = z.custom<ZodUnionDef>((x: unknown) => {
    const result = zodTypeDefSchema.safeParse(x)
    if (!result.success) return false
    return result.data.typeName === ZodFirstPartyTypeKind.ZodUnion
  })
  const tryGetValue = (x: object | undefined): number | undefined => {
    const result = z.object({ value: z.number() }).safeParse(x)
    return result.success ? result.data.value : undefined
  }

  const resultUnion = zodUnionDefSchema.safeParse(def)
  if (resultUnion.success)
    return resultUnion.data.options
      .map(({ _def }) => getMax(_def))
      .find((n: number | undefined) => n)

  const resultString = zodStringDefSchema.safeParse(def)
  if (resultString.success)
    return tryGetValue(
      resultString.data.checks.find(({ kind }) => kind === 'max')
    )

  return undefined
}
