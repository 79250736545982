import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_80b188d2_oc1p4iz0d3 from "/home/circleci/project/.nuxt/templates.pwa.client.80b188d2.ts";
import composition_sLxaNGmlSL from "/home/circleci/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/circleci/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fetch_bM55NWoqAA from "/home/circleci/project/src/plugins/_vaf/fetch.ts";
import runtimeConfig_snp5mMkMCS from "/home/circleci/project/src/plugins/_vaf/runtimeConfig.ts";
import fix_scroll_position_client_SSdo9OLV9z from "/home/circleci/project/src/plugins/fix-scroll-position.client.ts";
import sentry_client_KAXFuL2wum from "/home/circleci/project/src/plugins/sentry.client.ts";
import toast_client_5GxzM6P4QL from "/home/circleci/project/src/plugins/toast.client.ts";
import vee_validate_client_IWPu7ICJye from "/home/circleci/project/src/plugins/vee-validate.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_80b188d2_oc1p4iz0d3,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  fetch_bM55NWoqAA,
  runtimeConfig_snp5mMkMCS,
  fix_scroll_position_client_SSdo9OLV9z,
  sentry_client_KAXFuL2wum,
  toast_client_5GxzM6P4QL,
  vee_validate_client_IWPu7ICJye
]