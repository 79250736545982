import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default defineNuxtPlugin((nuxtApp) => {
  const env = nuxtApp.$config.public.NUXT_ENV_OUTPUT_ENV

  // localでは実行しない
  if (env !== 'production' && env !== 'staging') return

  const isDevelop = env === 'staging'
  const router = useRouter()

  Sentry.init({
    app: nuxtApp.vueApp,
    // プロジェクトの一意のアドレス（DataSourceName）
    dsn: nuxtApp.$config.public.NUXT_SENTRY_DSN,
    // SDKで使うintegrations
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        /**
         * sentry-trace ヘッダーと Baggage ヘッダーを付与するリクエスト先
         * @see https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
         * sentry-tracee: https://develop.sentry.dev/sdk/performance/#header-sentry-trace
         * baggage: https://develop.sentry.dev/sdk/performance/dynamic-sampling-context/#baggage
         */
        tracePropagationTargets: [
          /^\//,
          'localhost',
          'staging.asset-upload.hikky.dev',
          'asset-upload.vket.com',
          'asset-upload.hikky.dev',
          'resources.api.hikky.dev',
          'resources.api.vket.com',
        ],
      }),
    ],

    /** パフォーマンス監視などに使用されるトランザクションをどれくらい利用するか
     * @see https://docs.sentry.io/product/performance/transaction-summary/
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    tracesSampleRate: 0.1,
    /**
     * コンポーネントのパフォーマンス監視
     * @see https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
     */
    tracingOptions: {
      // コンポーネントのトラッキング
      trackComponents: true,
      // レンダリング情報を送る時の待ち時間コンポーネントレンダリング毎にdebounceされる
      // timeout: 500,
      // どのフックをトラッキングするか
      // hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    },
    // エラー送信前の処理
    beforeSend(event, hint) {
      if (!isDevelop) return event

      console.info(event)
      console.info(hint)
      return event
    },
    // デバッグログをconsoleに出すか出さないか
    debug: isDevelop,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // dataフォルダ配下
      /\/data\//i,
    ],
  })
})
