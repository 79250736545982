import validate from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/validate.js";
import locales_45global from "/home/circleci/project/src/middleware/locales.global.ts";
import refirect_45global from "/home/circleci/project/src/middleware/refirect.global.ts";
import router_45global from "/home/circleci/project/src/middleware/router.global.ts";
export const globalMiddleware = [
  validate,
  locales_45global,
  refirect_45global,
  router_45global
]
export const namedMiddleware = {}