
// @ts-nocheck
import locale__home_circleci_project_src_locales_ja_json from "../src/locales/ja.json";
import locale__home_circleci_project_src_locales_en_json from "../src/locales/en.json";


export const localeCodes =  [
  "ja",
  "en"
]

export const localeMessages = {
  "ja": [{ key: "../src/locales/ja.json", load: () => Promise.resolve(locale__home_circleci_project_src_locales_ja_json), cache: true }],
  "en": [{ key: "../src/locales/en.json", load: () => Promise.resolve(locale__home_circleci_project_src_locales_en_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/utils/i18n.ts?hash=9bb35f9b&config=1" /* webpackChunkName: "__src_utils_i18n_ts_9bb35f9b" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./src/utils/i18n.ts",
  "locales": [
    {
      "code": "ja",
      "iso": "ja-JP",
      "isCatchallLocale": true,
      "files": [
        "locales/ja.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        "locales/en.json"
      ]
    }
  ],
  "defaultLocale": "ja",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": "./locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "ja",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://my-vket.hikky.dev",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "api": false,
    "server": false
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "ja",
      "iso": "ja-JP",
      "isCatchallLocale": true,
      "files": [
        {
          "path": "locales/ja.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        {
          "path": "locales/en.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

