export default defineNuxtRouteMiddleware((to, _from) => {
  // @see https://hikky.atlassian.net/browse/PMVK-1840
  if (to.path === '/vketsummerreal2023') {
    return navigateTo('/')
  }

  // 旧URLのリダイレクト
  let path = to.path
  path = path.replace(/\/mypage\/avatar$/, '/mypage/assets/avatar')
  path = path.replace(/\/mypage\/avatar\/([^/]+)$/, '/mypage/assets/$1')
  path = path.replace(/\/avatar\/uploader/, '/mypage/assets/uploader')
  path = path.replace(/\/mypage\/relationship\/following/, '/mypage/follow')
  path = path.replace(/\/mypage\/relationship\/follower/, '/mypage/follower')
  // 無限ループ対策
  if (/^(\/en)?\/world/.test(path)) {
    path = path.replace(/\/world/, '/play/world')
  }

  if (path !== to.path) {
    return navigateTo(path)
  } else {
    // 削除されるパス
    const deletePaths = [
      /^(\/en)?\/avatar\/?$/,
      /^(\/en)?\/avatar\/([^/]+)\/?$/,
      /^(\/en)?\/achievement\/?$/,
      /^(\/en)?\/achievement\/list\/?$/,
      /^(\/en)?\/cloudstore\/asset\/?$/,
      /^(\/en)?\/cloudstore\/asset\/?$/,
      /^(\/en)?\/event\/?$/,
      /^(\/en)?\/event\/([^/]+)\/?$/,
      /^(\/en)?\/mypage\/cloudstore\/bought\/?$/,
      /^(\/en)?\/mypage\/cloudstore\/exhibited\/?$/,
      /^(\/en)?\/mypage\/cloudstore\/new\/?$/,
      /^(\/en)?\/mypage\/event\/?$/,
      /^(\/en)?\/myroom\/?$/,
      /^(\/en)?\/profile\/([^/]+)\/avatar\/?$/,
      /^(\/en)?\/tutorial\/([^/]+)\/?$/,
      /^(\/en)?\/test\/?$/,
      /\/profile\/(.+)\/relationship\/follower/,
      /\/profile\/(.+)\/relationship\/follow/,
      /^(\/en)?\/terms\/?$/,
      /^(\/en)?\/cloudstore\/list\/?$/,
    ]
    deletePaths.forEach((deletePath) => {
      if (deletePath.test(to.path)) {
        // トップへリダイレクトする
        return navigateTo('/')
      }
    })
  }
})
