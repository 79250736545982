<template>
  <img
    v-if="isLazy"
    loading="lazy"
    class="ha-image"
    :src="imageUrl"
    :alt="alt"
    :width="width"
    :height="height"
    @load="emit('loaded')"
    @error="undefinedImage"
  />
  <img
    v-else
    class="ha-image"
    :src="imageUrl"
    :alt="alt"
    :width="width"
    :height="height"
    @load="emit('loaded')"
    @error="undefinedImage"
  />
</template>

<script lang="ts" setup>
type Props = {
  src?: string | null
  alt?: string
  width?: number | undefined
  height?: number | undefined
  isLazy?: boolean
  noImage?: string
}

const props = withDefaults(defineProps<Props>(), {
  src: null,
  alt: '',
  width: undefined,
  height: undefined,
  isLazy: true,
  noImage: '',
})

type Emits = {
  (e: 'loaded'): void
}
const emit = defineEmits<Emits>()

const imageUrl = computed(() => {
  if (props.src) return props.src
  if (props.noImage) return props.noImage
  return '/images/no-image-1024x512.webp'
})

const undefinedImage = (element: Event): void => {
  if (!(element.target instanceof HTMLImageElement)) return

  element.target.src = props.noImage || '/images/no-image-1024x512.webp'
}
</script>
