import dayjs, { ManipulateType } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/ja'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// NOTE: import { locale, extend } するとbuild後、実行時にエラーになるため下記warnは無視している
/* eslint-disable import/no-named-as-default-member */
dayjs.locale('ja')

dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.extend(localeData)
dayjs.extend(customParseFormat)
/* eslint-enable */

const locale = {
  0: 'ja',
  1: 'en',
} as const

type LocaleData = (typeof locale)[keyof typeof locale]

// イベント開催期間(JST)
export const vket2023SummerStartDateTime = dayjs
  .tz('2023-07-15T10:00:00')
  .toDate()
export const vket2023SummerEndDateTime = dayjs.tz('2023-07-3023:00:00').toDate()

export const vketBoothMarcheStartDateTime = dayjs
  .tz('2024-05-13T11:00:00')
  .toDate()
export const vketBoothMarcheEndDateTime = dayjs
  .tz('2024-07-01T23:59:59')
  .toDate()

// イベント最小日
export const eventMinDate = '2023-04'

// イベント最大日
export const eventMaxDate = dayjs().add(1, 'y').format('YYYY-MM')

export function changeDateLocale(locale: LocaleData) {
  /* eslint-disable-next-line import/no-named-as-default-member */
  dayjs.locale(locale)
}

/**
 * @description Vket2023Summerの終了前であるか
 * @returns Result:Boolean
 */
export function isBeforeEndVket2023Summer(): boolean {
  return isBeforeTargetDate(vket2023SummerEndDateTime)
}

/**
 * @description 日付取得・フォーマット
 * @returns Result:Date
 */
export function formatDate(
  format: string,
  date: Date | string = new Date()
): string {
  return dayjs(date).format(format)
}

export function formatEnglishDate(date: Date | string): string {
  return formatDate('LL', date)
}

export function formatJapaneseDate(date: Date | string): string {
  return formatDate('YYYY/MM/DD', date)
}

export function formatEnglishDateTime(date: Date | string): string {
  return formatDate('LL LT', date)
}

export function formatJapaneseDateTime(date: Date | string): string {
  return formatDate('YYYY/MM/DD h:mm A', date)
}

export function formatDateUnixTime(date: Date | string = new Date()): number {
  return dayjs(date).unix()
}

export function formatTime(date: Date | string): string {
  return formatDate('HH:mm', date)
}

export function formatDateTimeLocal(date: Date | string): string {
  return formatDate('YYYY-MM-DDTHH:mm', date)
}

export function formatDateTime(date: Date | string): string {
  return formatDate('YYYY/MM/DD HH:mm', date)
}

/** イベント期間EN表示へフォーマット（TimeZoneの変更は行わない） */
export function formatEnglishEventTime(
  startDate: Date | string,
  endDate?: Date | string
): string {
  if (endDate) {
    return `${formatDate('LL LT', startDate)}~${formatDate('LT', endDate)}`
  }
  return formatDate('LL LT~', startDate)
}

/** イベント期間JA表示へフォーマット（TimeZoneの変更は行わない） */
export function formatJapaneseEventTime(
  startDate: Date | string,
  endDate?: Date | string
): string {
  if (endDate) {
    return `${formatDate('YYYY/MM/DD HH:mm', startDate)}~${formatDate(
      'YYYY/MM/DD HH:mm',
      endDate
    )}`
  }
  return formatDate('YYYY/MM/DD HH:mm~', startDate)
}

/** メッセージ時刻へフォーマット */
export function formatMessageTime(
  date: Date | string,
  locale: LocaleData
): string {
  if (dayjs().isSame(date, 'day')) {
    // 日付が同じならHH:mm
    return formatTime(date)
  } else if (dayjs().add(-1, 'day').isSame(date, 'day')) {
    // 日付が昨日なら「昨日」
    return locale === 'ja' ? '昨日' : 'yesterday'
  } else {
    // 昨日以前ならYYYY/MM/DD
    return formatDate(locale === 'ja' ? 'YYYY/MM/DD HH:mm' : 'll', date)
  }
}

// タイムゾーンをJSTからローカルのタイムゾーンに変更
export function formatJSTtoLocalTimezone(date: string | Date): string {
  const dateJST = dayjs(date).tz('Asia/Tokyo', true)
  const dateUSJ = dayjs(dateJST).tz('UTC').format('YYYY-MM-DDTHH:mm')
  return dayjs(dateUSJ).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm')
}

// タイムゾーンをローカルのタイムゾーンからJSTに変更
export function formatLocalTimezoneToJST(date: string | Date): string {
  const dateWithTimezone = dayjs(date).tz(dayjs.tz.guess(), true)
  return dayjs(dateWithTimezone).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm')
}

export function getCurrentDate(locale: LocaleData = 'ja'): string {
  const date = new Date()
  return locale === 'ja' ? formatJapaneseDate(date) : formatEnglishDate(date)
}

// ユーザのローカルのタイムゾーンを取得
export function getLocalTimezone(): string {
  return 'UTC ' + dayjs().tz(dayjs.tz.guess()).format('Z')
}

// 日付加算されたDateオブジェクトの作成
export function addDateTime(
  value: number,
  unit: ManipulateType = 'day',
  base: Date | string
) {
  return dayjs(base).add(value, unit).toDate()
}

/**
 * @description 時差対応
 * @returns Result:Date
 */
export function convertTimeToUtc(date: Date | string): string {
  return dayjs(date).utc().format()
}

/**
 * @description 指定日時の比較判定
 * @returns Result:Boolean
 */

export function diffDays(
  to: Date | string,
  from: Date | string | undefined,
  format: dayjs.UnitType = 'day'
): number {
  const toDate = dayjs(to)
  const fromDate = from ? dayjs(from) : dayjs()
  return fromDate.diff(toDate, format)
}

/** 現在時刻がdateの前かどうか判定 */
export function isBeforeTargetDate(date: Date | string): boolean {
  return !!dayjs().isSameOrBefore(dayjs(date))
}

/** 現在時刻がfromDateとtoDateの間かどうか判定 */
export function isBetweenTargetDates(
  fromDate: Date | string,
  toDate: Date | string
): boolean {
  return !!dayjs().isBetween(dayjs(fromDate), dayjs(toDate))
}

export function isAfterTargetDate(date: Date | string): boolean {
  return !!dayjs().isSameOrAfter(dayjs(date))
}

/** toがfromの翌日かどうか判定 */
export function isAfterDay(to: Date | string, from: Date | string) {
  return dayjs(from).isAfter(to, 'day')
}

/** toがfrom後の時間かどうか判定 */
export function isAfter(to: Date | string, from: Date | string) {
  return dayjs(to).isAfter(from)
}

/**
 * 判定日付が１週間以内か判定
 * ・１週間以内の場合：true
 * @param date 判定日付
 */
export function isAfterOneWeek(date: Date | string): boolean {
  const oneWeakBefore = dayjs().add(-1, 'w')
  const targetDate = dayjs(date)

  return targetDate.isAfter(oneWeakBefore)
}

function monthFormatter(month: number) {
  return month < 9 ? `0${month + 1}` : (month + 1).toString()
}

/**
 * Dayjsから各日付の数値取得
 */
export function getDateParams(date: string, locale?: LocaleData) {
  const month = dayjs(date).get('month')
  const day = dayjs(date).get('day')
  const weekdays = locale ? getWeekdaysShort(locale) : []
  return {
    year: dayjs(date).get('year').toString(),
    month: locale === 'ja' ? monthFormatter(month) : monthFormatter(month),
    day: dayjs(date).get('date'),
    dayofweek: weekdays[day],
    time: dayjs(date).format('HH:mm').toString(),
    // TODO: 必要であればminutes, seconds追加
  }
}

export function getCurrentDateParams() {
  const currentDate = dayjs()

  if (currentDate.isBefore(eventMinDate)) {
    return getDateParams(eventMinDate)
  }

  if (currentDate.isAfter(dayjs(eventMaxDate))) {
    return getDateParams(eventMaxDate)
  }

  return getDateParams(currentDate.format())
}

/**
 * イベント入力可能最小日より前の日付か
 */
export const isBeforeEventMinDate = (date: string) =>
  dayjs(date).isSameOrBefore(eventMinDate)

/**
 * イベント入力可能最大日より後の日付か
 */
export const isAfterEventMaxDate = (date: string) =>
  dayjs(date).isSameOrAfter(eventMaxDate)

/**
 * YYYY-MMの前の月の日付の値取得
 */
export const getPreviousYearAndMonth = (date: string) =>
  getDateParams(dayjs(date).subtract(1, 'month').format())

/**
 * YYYY-MMの次の月の日付の値取得
 */
export const getNextYearAndMonth = (date: string) =>
  getDateParams(dayjs(date).add(1, 'month').format())

export function getStartOfDay(date: string) {
  if (!isValidDateString(date)) return
  return dayjs(date).startOf('month').day()
}

export function getEndOfDay(date: string) {
  if (!isValidDateString(date)) return
  return dayjs(date).endOf('month').day()
}

export function getEndOfDate(date: string) {
  if (!isValidDateString(date)) return
  return dayjs(date).endOf('month').get('date')
}

/**
 * localizeされた[月, 火, 水, ... ]を取得
 */
export function getWeekdaysShort(locale: LocaleData) {
  changeDateLocale(locale)
  return dayjs.weekdaysShort()
}

/**
 * 日付文字列が正しいかどうか
 */
export function isValidDateString(date: string, format?: string) {
  return format ? dayjs(date, format).isValid() : dayjs(date).isValid()
}

/**
 * YYYY-MMの日付文字列に変換できる文字列かどうか
 */
export function isValidYearAndMonth(date: string) {
  return isValidDateString(date, 'YYYY-MM')
}

/**
 * 今日かどうか
 */
export function isToday(date: string | Date) {
  return dayjs(date).isSame(dayjs(), 'day')
}

/**
 * localeに応じた月名を取得する
 * 第一引数のmonth-1の月名が取得可能 (例: month:0 -> 1月 or January)
 */
export function getMonthName(month: number, locale: LocaleData) {
  // 0~11の数値でなければ不正な月として空文字を返す
  if (month < 0 || month > 11) return ''

  changeDateLocale(locale)
  return dayjs().month(month).format('MMMM')
}
